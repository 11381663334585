html, body, #root {
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

#root {
    /*background-color: #152036;*/
    background-color: transparent;
    color: white;
}
.MuiDrawer-paper {
    background-color: #152036;
    /*background-color: transparent;*/
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-1l8j5k8 {
    background-color: #152036 !important;
    /*background-color: transparent !important;*/
    border-right: none !important;
}

.css-zxdg2z {
    padding: 0 !important;
    width: 96%;
    margin: 0 auto;
}

/*.css-1191obr-MuiPaper-root-MuiAppBar-root {*/
/*    border-bottom: 1px solid rgba(86, 92, 114, 0.44);*/
/*}*/

.css-h4y409-MuiList-root {
    padding: initial !important;
    /* margin-left: 33px !important; */
    margin-top: 50px !important;
}

.css-10hburv-MuiTypography-root, .css-yb0lig {
    margin: 15px 25px !important;
}

header {
    box-shadow: none !important;
}

.css-1r9jet7 {
    padding: 0 !important;
}

.css-glg1l6-JoyCircularProgress-progress, .css-1iy5c3l {
    animation:  var(--CircularProgress-circulation, 4.8s linear 0s infinite normal none running) animation-xnrbr3 !important;
}


.css-hyum1k-MuiToolbar-root, .css-i6s8oy {
    justify-content: space-between !important;
}
/* .css-1ps6pg7-MuiPaper-root {
    border-right: 1px solid #6f6f6f52 !important;
} */

/*@media (min-width: 600px) {*/
/*    .css-hyum1k-MuiToolbar-root {*/
/*        min-height: 63px!important;*/
/*    }*/
/*}*/

.css-10hburv-MuiTypography-root {
    font-size: 15px !important;
}