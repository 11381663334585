@charset "UTF-8";
/* LOGO */
.logo_img {
  margin: 8px 0;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 64px;
  border-bottom: 1px solid rgba(111, 111, 111, 0.14);
}

.logo_name {
  font-weight: 700;
  color: white;
}

/* SEARCH */
.search {
  position: relative;
  width: 100%;
}

.search_img {
  width: 20px;
  position: absolute;
  top: 10px;
  right: 15px;
}

.input_search {
  width: 100%;
  background-color: #152036;
  height: 40px;
  border: 2px solid #353a53;
  padding: 0 10px;
  color: white;
}

.input_search:focus {
  outline: none;
}

/* PROFILE */
.profile {
  display: flex;
  margin: 25% 0;
  height: 85%;
  flex-direction: column;
  justify-content: space-around;
}

.profile_img {
  margin: 0 auto;
  max-width: 110px;
  border-radius: 50%;
  border: solid #0299f0 3px;
}

.profile {
  color: white;
}

/* NAV_IMG  */
.nav_img {
  max-width: 19px;
}

.navBar {
  max-height: 40px;
}

.settings {
  max-width: 40px;
  border-radius: 50%;
}

.settings:hover {
  cursor: pointer;
}

.border {
  width: 33px;
  bottom: 0;
  border-bottom: 1px solid rgba(111, 111, 111, 0.14);
  position: absolute;
  height: 18px;
  background-color: rgb(27, 42, 71);
}

.menu {
  position: absolute;
  top: 50px;
  width: 150px;
  right: 20px;
  background-color: rgb(27, 42, 71);
  border: 1px solid rgba(111, 111, 111, 0.36);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu img {
  width: 30px; /* Размер картинки настройки и выхода, можно настроить */
}

.menu span {
  margin-top: 5px;
  cursor: pointer;
}

.settings_menu {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: flex-start;
}

.settings_menu:hover {
  cursor: pointer;
}

/* .border_profile {
        position: absolute;
        right: 0;
        height: 20px;
        border-right: 1px solid #6f6f6f52;

} */


