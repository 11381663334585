html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  background: #121212; /* fallback for old browsers */
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  height: 100%;

  /* code to make all text unselectable */
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* Disables selector ring */
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

/* ########################################################## */

.flex_container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.not__found {
  position: relative;
  height: 100vh;
  background: transparent;
}

.not__found .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound_404 {
  position: relative;
  height: 180px;
  margin-bottom: 20px;
  z-index: -1;
}

.notfound .notfound_404 h1 {
  font-family: 'Inter', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50% , -50%);
  -ms-transform: translate(-50% , -50%);
  transform: translate(-50% , -50%);
  font-size: 224px;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -12px;
  color: #152036;
  text-transform: uppercase;
  text-shadow: -1px -1px 0px #6089a7, 1px 1px 0px #5febeb;
  letter-spacing: -20px;
}


.notfound .notfound_404 h2 {
  font-family: 'Inter', sans-serif;
  position: absolute;
  left: 0;
  right: 0;
  top: 110px;
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 2px 0 #32bdd3;
  letter-spacing: 13px;
  margin: 0;
}

.notfound a {
  font-family: 'Inter', sans-serif;
  display: inline-block;
  text-transform: uppercase;
  color: #b8bdcb;
  text-decoration: none;
  border: 2px solid;
  background: transparent;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: #3ac8c9;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound_404 h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound_404 h1 {
    font-size: 182px;
  }
}
