.links {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
}

.link {
  text-align: center;
  position: relative;
  width: 40%;
  height: 20%;
}
.link img {
  margin: 5px auto;
  width: 50%;
  display: block;
  //width: 100%;
  cursor: pointer;
}
.link:nth-child(1) {
  padding: 1.5%;
}

.link:nth-child(1) img:hover {
  box-shadow: 0 6px 20px rgb(46, 74, 124)
}

.link:nth-child(2) img:hover {
  box-shadow: 6px 6px 20px rgba(23, 39, 65, 1)
}

.text {
  font-size: 12px;
  width: 100%;
  color: white;
  text-align: center;
}
