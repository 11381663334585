@keyframes rotateIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@keyframes rotateIconBack {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.eject_icon {
  margin-top: 8px;
  pointer-events: none;
  font-size: 0.9rem !important;
  animation: rotateIconBack 0.3s ease forwards;
  transition: transform 0.3s ease; /* Optional: Add smooth transition */
}

.eject_icon:hover {
  color: #5d75a4;
}

.rotated {
  animation: rotateIcon 0.3s ease forwards;
}

