.container {
    margin: 3%;
    position: relative;

}

.dashboard {
    padding-bottom: 0;
}

.progress_block {
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
}

.servers, .services, .nodes {
    background-color: rgb(27 42 71 / 35%);
    width: 30%;
    height: 500px;
    border-radius: 2%;
    padding: 30px;
}

.welcome {
    font-size: 20px;
}

p {
    color: white;
    font-size: 34px;
}

.title {
    margin: 0;
    font-size: 20px;
    margin-bottom: 0;
    font-weight: normal;
}

.progress_servers {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.description {
    font-size: 20px;
    color: #c3c3c3;

}

@media (max-width: 1200px) {
    .servers, .services, .nodes {
        height: 400px;
    }
    .description {
        font-size: 14px;
        text-align: center;
    }
    .progress_block {
        margin-top: 14%;
    }
   
}

@media (max-width: 1000px) {
    .servers, .services, .nodes {
        height: 300px;
        margin: 20px;
        width: 40%;
    }
    .description {
        font-size: 14px;
        text-align: center;
    }
   
    .progress_block {
        display: flex;
        justify-content: space-around;
        margin-top: 5%;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        align-content: stretch;

    }
   
}

@media (max-width: 700px) {
    .servers, .services, .nodes {
        height: 300px;
        margin: 20px;
        width: 80%;
    }
    .description {
        font-size: 14px;
        text-align: center;
    }
   
    .progress_block {
        display: flex;
        margin-top: 5%;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

    }
   
}