
.tables {
  overflow-x: hidden !important;
  max-height: 94vh; /* Set the maximum height of the container to enable scrolling */
  overflow-y: auto; /* Enable vertical scrolling for the container */
}

thead th {
  position: sticky;
  top: -0.5px; /* This will fix the header at the top of the container */
  z-index: 1; /* Ensure the header is above the table content */
}

table{
  width: 96%;
  border-collapse: collapse;
  text-align: left;
  margin: 2% auto;

  td, th{
    border: 1px solid rgba(86, 92, 114, 0.44);

    padding: 10px;
    text-align: center;
  }

  td{
    border: 1px solid rgba(86, 92, 114, 0.44);
  }

  th{
    background-color: #1b2a47;
  }

  //tr:nth-of-type(even) td{
  //  background-color: #1b2a47;
  //}

  .total{
    th{
      background-color: #1e2742;
    }

    td{
      text-align: right;
      font-weight: 700;
    }
  }
}

.mobile-header{
  display: none;
}

.ordering_block {
  font-size: 16px;
  width: 100%;
  transition: all .1s linear;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.ordering_block div:first-child {
  cursor: pointer;
}
.ordering_block div:first-child:hover {
  color: #98dcff;
  transition: all .2s linear;
}
.ordering:hover {
  background-color: #101f3a;
}

.sortoptions {
  display: none;
}

.sortpriority {
  margin-left: 12px;
  font-size: 9px;
}

.sortremove {
  cursor: pointer;
  position: absolute;
  top: -4px;
  left: 2px;
  transition: .2s linear;
}
.sortremove:hover {
  transform: rotate(180deg);
}

.toggle_ordering {
  vertical-align: middle;

}
